
import { Component, Prop, Vue } from "vue-property-decorator";
import ApiService from "../services/ApiService";

interface Vacancy {
  Reference: string;
  VacancyName: string;
  Location: string;
  Status: number;
}

@Component({})
export default class JobVacanciesList extends Vue {
  @Prop() private customClass!: string;

  public vacancyImg = require("@/assets/join-us-icons/vacancy.png");
  public vacancies = {};

  async mounted() {
    this.currentVacancies = await this.getJobs();
  }

  set currentVacancies(value) {
    this.vacancies = value;
  }

  get currentVacancies() {
    return this.vacancies;
  }

  public async getJobs() {
    const data = await ApiService.get(
      "https://q4o3fv6vuc.execute-api.eu-west-2.amazonaws.com/production/"
    );

    let vacancies = {};

    if (!data.isError && data.result && data.result.length > 0) {
      vacancies = JSON.parse(data.result);
    }

    return vacancies;
  }
}
