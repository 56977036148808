
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import MainBanner from "@/components/MainBanner.vue";
import TitleText from "@/components/TitleText.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import PathScroller from "@/components/PathScroller.vue";
import PageBreak from "@/components/PageBreak.vue";
import JobVacanciesList from "@/components/JobVacanciesList.vue";
import Form from "@/components/Form.vue";
import ApiService from "@/services/ApiService";
import { JoinUsForm as IJoinUsForm } from "@/interfaces/JoinUsForm.interface";
import IconList from "@/components/IconList.vue";

@Component<any>({
  metaInfo() {
    return {
      title: "Working at Leighton - Tech, Talent, Together",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `${this.banner.text}`
        }
      ]
    };
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    CardShowcase,
    PathScroller,
    PageBreak,
    JobVacanciesList,
    Form,
    IconList
  }
})
export default class WorkingAtLeighton extends Vue {
  private banner = {
    heading: "We make the improbable, ",
    emphasis: "possible!",
    text:
      "Be part of our journey to become the North’s leading AWS Software Developer.",
    action: {
      text: "Join our mission",
      action: "/join-us/current-vacancies"
    },
    image: require("@/assets/banner-images/join-us.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title: `
      Our Values
    `,
    subtitle: `
      Our mission is to help businesses and their customers thrive in today’s fast paced world. We’re just as passionate about helping our people thrive through career progression and training.  Ok, so the first thing you need to know is we love what we do and we never settle for second best.    We always take ownership of our actions and reflect on the work that we have done so we are even better next time.
      <br><br>
      Our values underpin everything we do and how we act. Without them we can’t achieve your dreams.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble5-small.png"),
        type: "bubble-one",
        top: "-100px",
        right: "1150px"
      },
      {
        image: require("@/assets/page-decorators/orange-pill-inverted.png"),
        type: "bubble-one",
        bottom: "-400px",
        right: "-350px"
      }
    ]
  };

  private cards = {
    customClass: "mt-5 pb-md",
    icons: true,
    showcaseTypeDesktop: "single-scroller",
    showcaseTypeMobile: "single-scroller",
    values: [
      {
        icon: require("@/assets/values-icons/value1.png"),
        title: "Our curiosity inspires the amazing",
        text:
          "Our curiosity is boundless, we ask lots of questions and are open minded and we love exploring new ideas.  We aren’t afraid of failure and love to push at the boundaries of the possible as we seek positive change."
      },
      {
        icon: require("@/assets/values-icons/value2.png"),
        title: "Passionate about what we do, proud of what we achieve",
        text:
          "Ok, so the first thing you need to know is we love what we do and we never settle for second best. We always take ownership of our actions and reflect on the work that we have done so we are even better next time."
      },
      {
        icon: require("@/assets/values-icons/value3.png"),
        title: "We act with honesty and truth, always!",
        text:
          "We do what we say we are going to do, when we say we will do it – always! We use good judgement and do the “right thing”."
      },
      {
        icon: require("@/assets/values-icons/value4.png"),
        title:
          "We nurture our amazing people to be the best version of themselves",
        text:
          "We never stop learning and constantly want to improve. At Leighton, everyone is a teacher and a student. That is why our customers keep coming back for more."
      },
      {
        icon: require("@/assets/values-icons/value5.png"),
        title:
          "We collaborate to bring our purpose to life in a caring and supportive way",
        text:
          "So, we admit it, we are different to everyone else. We really care about our customers and each other. We want to make a difference, we will deliver, we value you and will support you in the pursuit of your goals."
      }
    ]
  };

  private titleText2 = {
    customClass: "mt-md",
    title: `
      Get results quickly
      
    `,
    subtitle: `
      The second thing to know is you’ll be making a difference quickly when you join Leighton. You'll be part of a diverse but like minded team. Your work family. You'll work with us, challenge us and have fun with us (only if you want to!) and you’ll be learning with us too. 
      <br><br>
      Using our own <span class="emphasise--dark emphasise--dark--underline">software</span> we’ll give you the perfect start to your career at Leighton. From meeting your colleagues to understanding <a href="/about#path">our methodology</a> we’ve got you covered so you can quickly be getting results for our customers. 
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-one",
        top: "50px",
        right: "-300px"
      }
    ]
  };

  private titleText3 = {
    customClass: "mt-4",
    title: `
      Grow your own career path
    `,
    subtitle: `     
      We're as committed as you, to your long term career development. We've developed our own 'grow your own career path' model
    `
  };

  private pathScroller = {
    customClass: "mt-md pb-md",
    pathElements: [
      {
        icon: require("@/assets/pathscroller-images/path-1.png"),
        text:
          "Regular check-ins, focusing on your strengths and development aspirations to enable you to grow the career you want",
        alt: "Regular check-ins"
      },
      {
        icon: require("@/assets/pathscroller-images/path-2.png"),
        text:
          "Hackathons, training and much more with partners like AWS and Linkedin Learning",
        alt: "Hackathons, training and much more"
      },
      {
        icon: require("@/assets/pathscroller-images/path-3.png"),
        text:
          "Enjoy peer-to-peer learning and share your experiences wih your colleagues via learning lunches, workshops, and project retrospectives",
        alt: "Peer to peer learning"
      },
      {
        icon: require("@/assets/pathscroller-images/path-4.png"),
        text:
          "Progress your career with mentor programmes and project secondments",
        alt: "Mentor programmes and project secondments"
      }
    ]
  };

  private vacanciesLink = {
    buttonText: "View Vacancies",
    buttonLink: "/join-us/current-vacancies#vacancies",
    buttonLabel: "View our current vacancies"
  };

  private titleText4 = {
    customClass: "mt-md",
    title: `The Benefits`,
    subtitle: `
      We understand that it isn’t just about doing great work, learning new skills and progressing your career… that’s why we look to provide some great benefits to help you have a great work/life fit.  
      <br>
      <span class="emphasise--dark">Have a look at a snapshot of what we provide...</span>`,
    decorator: [
      {
        image: require("@/assets/page-decorators/purple-pill-inverted.png"),
        type: "bubble-one",
        top: "300px",
        left: "-2500px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-medium.png"),
        type: "bubble-one",
        top: "150px",
        right: "-300px"
      },
      {
        image: require("@/assets/page-decorators/bubble5-small.png"),
        type: "bubble-one",
        top: "700px",
        right: "-150px"
      }
    ]
  };

  private benefitCards = {
    customClass: "benefit-cards mt-4 mb-5",
    showcaseTypeMobile: "single-scroller",
    showcaseTypeDesktop: "static",
    icons: true,
    squareTiles: true,
    values: [
      {
        icon: require("../../assets/benefit-images/flexible-working.png"),
        title: "Flexible Working",
        text:
          "Life and work should fit together so you choose when you want to work and when you need to pick up the kids from school"
      },
      {
        icon: require("../../assets/benefit-images/life-assurance.png"),
        title: "Life Assurance and Pension Scheme",
        text: "Protect your future with our Life Assurance and Pension Schemes"
      },
      {
        icon: require("../../assets/benefit-images/employee-assitance.png"),
        title: "Employee Assistance Programme",
        text: "Access a 24/7 Employee Assistance Programme"
      },
      {
        icon: require("../../assets/benefit-images/extra-holidays.png"),
        title: "25+ days holiday",
        text: "Enjoy time away from work with 25+ days holiday a year"
      },
      {
        icon: require("../../assets/benefit-images/incentive-schemes.png"),
        title: "Incentive Schemes",
        text:
          "Contribute to our success with a host of incentive schemes that recognise and reward your ideas"
      },
      {
        icon: require("../../assets/benefit-images/equipment-loan.png"),
        title: "Equipment Loan Scheme",
        text:
          "Get yourself the latest kit with our Computer Equipment Loan Scheme"
      }
    ]
  };

  private titleText5 = {
    customClass: "background--grey pt-0 mt-5",
    subTitle: `
      If you have any questions about any of our opportunities, please <span class="emphasise--dark">fill out the form below</span> and we’ll happily answer any questions you have.
      <br><br>
      We’re always keen to hear from amazing people, if there isn’t a role for you just yet, send us your CV anyway. You never know what the future holds. Please <span class="emphasise--dark">attach your CV</span> and the type of opportunity you're interested in to the <span class="emphasise--dark">form below</span>.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/leighton-pills-small.png"),
        type: "leighton-pills",
        top: "150px",
        right: "-200px"
      }
    ]
  };

  private titleText6 = {
    customClass: "background--grey p-0 m-0",
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "light-green-pill",
        top: "-800px",
        left: "-1400px"
      }
    ]
  };

  private form = {
    success: false,
    error: false,
    popUpMsg: ``,
    customClass: "background--grey pt-md mb-md",
    formHeading: "Get in touch",
    formData: {
      name: {
        value: "",
        errMsg: "Please enter your name",
        error: false
      },
      email: {
        value: "",
        errMsg: "Please enter a valid email",
        error: false
      },
      phone: {
        value: "",
        errMsg: "Please enter a valid phone number",
        error: false
      },
      message: {
        value: "",
        errMsg:
          "Please enter a minimum of 10 characters and maximum of 4000 characters",
        error: false
      },
      subject: {
        value: "",
        errMsg: "Please select an item from the dropdown",
        error: false
      },
      fileUpload: {
        value: FileList,
        errMsg: "There was an error uploading your file",
        error: false
      },
      fileNames: []
    },
    rows: [
      [
        {
          label: "Name",
          name: "name",
          type: "text",
          model: "name",
          required: true,
          disableSpace: false
        }
      ], //Row 1
      [
        {
          label: "Email Address",
          name: "email",
          type: "email",
          model: "email",
          required: true,
          disableSpace: true
        },
        {
          label: "Telephone Number",
          name: "phone",
          type: "tel",
          model: "phone",
          required: true,
          disableSpace: true
        }
      ], //row 2
      [
        {
          label: "Message",
          name: "message",
          type: "textarea",
          model: "message",
          required: true,
          rows: 5,
          cols: 50,
          disableSpace: false
        }
      ], //Row 2
      [
        {
          label: "Upload your CV here",
          name: "file",
          type: "file",
          model: "file",
          required: false,
          accept: ".doc, .docx, .pdf,  application/msword, application/pdf"
        }
      ] //Row 3
    ]
  };

  private regex = /(<([^>]+)>)/gi;

  private async sendForm(formData: IJoinUsForm) {
    formData.name.value = formData.name.value.replace(this.regex, "");
    formData.email.value = formData.email.value.replace(this.regex, "");
    formData.phone.value = formData.phone.value.replace(this.regex, "");
    formData.subject.value = 'Working at Leighton Form';
    formData.message.value = formData.message.value.replace(this.regex, "");

    // reset each error so that if its the users second attempt, the error does not stay visible upon success
    this.form.formData.name.error = false;
    this.form.formData.email.error = false;
    this.form.formData.phone.error = false;
    this.form.formData.subject.error = false;
    this.form.formData.message.error = false;

    const files = Array.from(formData.fileUpload.value);
    if (files.length > 0) {
      files.forEach(file => {
        formData.fileNames.push(file.name);
      });
    }

    const response = await ApiService.post(
      "https://g623sdseed.execute-api.eu-west-2.amazonaws.com/dev/submitted",
      formData
    );
    if (response.has_error) {
      if (response.messages.name.status === true) {
        this.form.formData.name.error = true;
      } else {
        this.form.formData.name.error = false;
      }
      if (response.messages.email.status === true) {
        this.form.formData.email.error = true;
      } else {
        this.form.formData.email.error = false;
      }
      if (response.messages.phone.status === true) {
        this.form.formData.phone.error = true;
      } else {
        this.form.formData.phone.error = false;
      }
      if (response.messages.subject.status === true) {
        this.form.formData.subject.error = true;
      } else {
        this.form.formData.subject.error = false;
      }
      if (response.messages.message.status === true) {
        this.form.formData.message.error = true;
      } else {
        this.form.formData.message.error = false;
      }
    } else {
      if (files.length > 0) {
        if (response.fileUploadUrl.length === 0) {
          this.form.formData.fileUpload.error = true;
        }
        for (let i = 0; i < response.fileUploadUrl.length; i++) {
          await ApiService.put(response.fileUploadUrl[i], files[i])
            .then(() => {
              this.form.popUpMsg = `
                <p>
                  Thank you for your message.
                </p>
                <p>
                  A member of the team will be in touch shortly.
                </p>
              `;
              this.form.error = false;
              this.form.success = true;
            })
            .catch(() => {
              this.form.popUpMsg = `
              <p>
                There was a problem uploading your file(s).
              </p>
            `;
              this.form.success = false;
              this.form.error = true;
            });
        }
      } else {
        this.form.error = false;
        this.form.success = true;
        this.form.popUpMsg = `
          <p>
            Thank you for your message.
          </p>
          <p>
            A member of the team will be in touch shortly.
          </p>
        `;
      }
    }
  }

  private closePopUp(): void {
    // if error message is shown, dont reset form when message is closed
    if (this.form.error) {
      this.form.error = false;
    } else {
      // if success message is shown reset the form when message is closed
      this.form.formData.name.value = "";
      this.form.formData.email.value = "";
      this.form.formData.phone.value = "";
      this.form.formData.subject.value = "";
      this.form.formData.message.value = "";
      this.form.formData.fileNames = [];
      this.$refs.fileInput = [];
      this.form.success = false;
    }
  }
}
